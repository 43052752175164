<template>
  <div class="links">
    <div class="column">
      <a href="https://mistobox.zendesk.com/hc/en-us">FAQ</a>
      <a href="/brew-guides">Coffee Brewing Guides</a>
      <a href="https://blog.mistobox.com">Blog</a>
      <a href="/about-mistobox">About MistoBox</a>
      <a href="mailto:info@mistobox.com" class="contactUs">info@mistobox.com <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 2.5 16.667969 L 2.5 3.332031 L 18.332031 10 Z M 3.75 14.730469 L 15.082031 10 L 3.75 5.207031 L 3.75 8.707031 L 8.792969 10 L 3.75 11.25 Z M 3.75 14.730469 L 3.75 5.207031 Z M 3.75 14.730469 "/>
</g>
</svg></a>
    </div>
    <div class="column">
      <a href="/shop">Browse All Coffees</a>
      <a href="/roasters">Browse Roasters</a>
      <a href="/press">Press</a>
      <a href="https://mistobox.zendesk.com/hc/en-us/articles/360026397434">Roast for MistoBox</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLinks"
};
</script>

<style lang="scss" scoped>
a {
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  color: #929292;
  &:hover {
    text-decoration: none;
    color: #929292;
  }
}
.contactUs{
  svg {
    position: relative;
    left: 0px;
    top: 5px;
  }
}
.links {
  display: flex;
  .column {
    display: flex;
    flex-direction: column;
    margin-right: 65px;
    a {
      display: inline-block;
      white-space: nowrap;
    }
  }
  margin-bottom: 53px;
}
</style>
