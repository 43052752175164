var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "links" }, [
    _c("div", { staticClass: "column" }, [
      _c("a", { attrs: { href: "https://mistobox.zendesk.com/hc/en-us" } }, [
        _vm._v("FAQ")
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/brew-guides" } }, [
        _vm._v("Coffee Brewing Guides")
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://blog.mistobox.com" } }, [
        _vm._v("Blog")
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/about-mistobox" } }, [
        _vm._v("About MistoBox")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "contactUs",
          attrs: { href: "mailto:info@mistobox.com" }
        },
        [
          _vm._v("info@mistobox.com "),
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                width: "20px",
                height: "20px",
                viewBox: "0 0 20 20",
                version: "1.1"
              }
            },
            [
              _c("g", { attrs: { id: "surface1" } }, [
                _c("path", {
                  staticStyle: {
                    stroke: "none",
                    "fill-rule": "nonzero",
                    fill: "rgb(0%,0%,0%)",
                    "fill-opacity": "1"
                  },
                  attrs: {
                    d:
                      "M 2.5 16.667969 L 2.5 3.332031 L 18.332031 10 Z M 3.75 14.730469 L 15.082031 10 L 3.75 5.207031 L 3.75 8.707031 L 8.792969 10 L 3.75 11.25 Z M 3.75 14.730469 L 3.75 5.207031 Z M 3.75 14.730469 "
                  }
                })
              ])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("a", { attrs: { href: "/shop" } }, [_vm._v("Browse All Coffees")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/roasters" } }, [_vm._v("Browse Roasters")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/press" } }, [_vm._v("Press")]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://mistobox.zendesk.com/hc/en-us/articles/360026397434"
          }
        },
        [_vm._v("Roast for MistoBox")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }