var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup" },
    [
      _vm.isSubmitted
        ? [
            _c("div", { staticClass: "submitted" }, [
              _vm._v("Thanks! We'll send you a note shortly.")
            ])
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              class: { error: _vm.isSubmitError },
              attrs: {
                disabled: _vm.isSubmitting,
                placeholder: "Your Email",
                type: "email"
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            !_vm.isSubmitting
              ? _c(
                  "button",
                  { staticClass: "submit", on: { click: _vm.submitEmail } },
                  [_vm._v("Submit")]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }