var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social" }, [
    _c("div", { staticClass: "friends" }, [_vm._v("Let's be friends")]),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: {
          href: "https://www.facebook.com/mistoboxcoffee",
          rel: "noreferrer",
          target: "_blank"
        }
      },
      [
        _vm.lazy
          ? [
              _c("img", {
                staticClass: "facebook lazyload",
                attrs: {
                  alt: "facebook",
                  "data-src":
                    "/skin/frontend/default/sns_korion/images/mbox/icons/facebook_new.png"
                }
              })
            ]
          : [
              _c("img", {
                staticClass: "facebook",
                attrs: {
                  alt: "facebook",
                  src:
                    "/skin/frontend/default/sns_korion/images/mbox/icons/facebook_new.png"
                }
              })
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: {
          href: "https://www.youtube.com/user/MistoBox",
          rel: "noreferrer",
          target: "_blank"
        }
      },
      [
        _vm.lazy
          ? [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  alt: "youtube",
                  "data-src":
                    "/skin/frontend/default/sns_korion/images/mbox/icons/youtube_icon_thin.png"
                }
              })
            ]
          : [
              _c("img", {
                attrs: {
                  alt: "youtube",
                  src:
                    "/skin/frontend/default/sns_korion/images/mbox/icons/youtube_icon_thin.png"
                }
              })
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: {
          href: "https://twitter.com/mistoboxcoffee",
          rel: "noreferrer",
          target: "_blank"
        }
      },
      [
        _vm.lazy
          ? [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  alt: "twitter",
                  "data-src":
                    "/skin/frontend/default/sns_korion/images/mbox/icons/twitter_new.png"
                }
              })
            ]
          : [
              _c("img", {
                attrs: {
                  alt: "twitter",
                  src:
                    "/skin/frontend/default/sns_korion/images/mbox/icons/twitter_new.png"
                }
              })
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: {
          href: "http://instagram.com/mistoboxcoffee",
          rel: "noreferrer",
          target: "_blank"
        }
      },
      [
        _vm.lazy
          ? [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  alt: "instagram",
                  "data-src":
                    "/skin/frontend/default/sns_korion/images/mbox/icons/instagram_new.png"
                }
              })
            ]
          : [
              _c("img", {
                attrs: {
                  alt: "instagram",
                  src:
                    "/skin/frontend/default/sns_korion/images/mbox/icons/instagram_new.png"
                }
              })
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }