<template>
  <div class="social">
    <div class="friends">Let's be friends</div>
    <a href="https://www.facebook.com/mistoboxcoffee" rel="noreferrer" target="_blank">
      <template v-if="lazy">
        <img
          alt="facebook"
          class="facebook lazyload"
          data-src="/skin/frontend/default/sns_korion/images/mbox/icons/facebook_new.png"
        />
      </template>
      <template v-else>
        <img
          alt="facebook"
          class="facebook"
          src="/skin/frontend/default/sns_korion/images/mbox/icons/facebook_new.png"
        />
      </template>
    </a>
    <a href="https://www.youtube.com/user/MistoBox" rel="noreferrer" target="_blank">
      <template v-if="lazy">
        <img
          alt="youtube"
          class="lazyload"
          data-src="/skin/frontend/default/sns_korion/images/mbox/icons/youtube_icon_thin.png"
        />
      </template>
      <template v-else>
        <img
          alt="youtube"
          src="/skin/frontend/default/sns_korion/images/mbox/icons/youtube_icon_thin.png"
        />
      </template>
    </a>
    <a href="https://twitter.com/mistoboxcoffee" rel="noreferrer" target="_blank">
      <template v-if="lazy">
        <img
          alt="twitter"
          class="lazyload"
          data-src="/skin/frontend/default/sns_korion/images/mbox/icons/twitter_new.png"
        />
      </template>
      <template v-else>
        <img
          alt="twitter"
          src="/skin/frontend/default/sns_korion/images/mbox/icons/twitter_new.png"
        />
      </template>
    </a>
    <a href="http://instagram.com/mistoboxcoffee" rel="noreferrer" target="_blank">
      <template v-if="lazy">
        <img
          alt="instagram"
          class="lazyload"
          data-src="/skin/frontend/default/sns_korion/images/mbox/icons/instagram_new.png"
        />
      </template>
      <template v-else>
        <img
          alt="instagram"
          src="/skin/frontend/default/sns_korion/images/mbox/icons/instagram_new.png"
        />
      </template>
    </a>
  </div>
</template>

<script>
export default {
  name: "Social",
  props: {
    lazy: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.social {
  display: flex;
  align-items: center;
  .friends {
    text-transform: uppercase;
    font-size: 16px;
    color: #929292;
    margin-right: 22px;
    line-height: 24px;
  }
  a {
    display: block;
    max-width: 24px;
    max-height: 24px;
    width: 24px;
    height: 24px;
    margin-right: 18px;
    img {
      vertical-align: baseline;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
