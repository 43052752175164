<template>
  <div id="footer-app" class="footer" :class="{compact}">
    <div class="main_footer">
      <div class="left">
        <img class="logo" src="/skin/frontend/default/sns_korion/images/logo.gif" />
        <FooterLinks class="links" />
        <div class="social-section">
          <FooterSocial :lazy="lazy" />
          <FooterSignup :lazy="lazy" class="signup" />
        </div>
      </div>
      <div class="right">
        <div class="subscribe">
          <a href="/quiz" class="quiz">Subscribe</a>
          <a href="/gift" class="gift">Gift</a>
        </div>
        <FooterLinks class="links" />
        <div class="social-section">
          <FooterSocial :lazy="lazy" />
          <FooterSignup :lazy="lazy" class="signup" />
        </div>
      </div>
    </div>
    <div class="sub_footer">
      <div class="spacer"></div>
      <div class="brand">© 2021 MistoBox</div>
      <div class="privacy">
        <a href="/privacy-policy" target="_new">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
import FooterLinks from "./footer/links.vue";
import FooterSocial from "./footer/social.vue";
import FooterSignup from "./footer/signup.vue";
export default {
  components: { FooterLinks, FooterSocial, FooterSignup },
  props: {
    compact: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #fff;
  font-family: "museo-sans", sans-serif;
  color: #bebebe;

  a {
    font-size: 15px;
    font-weight: 300;
    line-height: 2;
    color: #929292;
    &:hover {
      text-decoration: none;
      color: #929292;
    }
  }

  .main_footer {
    display: flex;
    justify-content: space-between;
    .left {
      .social-section {
        display: none;
      }
      margin-left: 96px;
      img.logo {
        width: 150px;
        margin-bottom: 43px;
        margin-top: 70px;
      }
    }
    .right {
      margin-right: 72px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .links {
        display: none;
      }
      .social-section {
        margin-bottom: 53px;
      }
      .subscribe {
        margin-top: 60px;
        display: flex;
        a {
          cursor: pointer;
          text-align: center;
          display: block;
          width: 146px;
          height: 53px;
          font-size: 16px;
          text-transform: uppercase;
          text-decoration: none;
          line-height: 53px;
          &.quiz {
            margin-right: 10px;
            color: white !important;
            background-color: #5f828d;
          }
          &.gift {
            color: #5f828d;
            border: solid 1.5px #5f828d;
          }
        }
      }
      div.mailing-list {
        margin-top: 35px;
        margin-right: 10px;
        position: relative;
        a.submit {
          position: absolute;
          right: 0px;
          bottom: 10px;
          display: block;
          width: 67px;
          height: 26px;
          text-transform: uppercase;
          line-height: 26px;
          text-align: center;
          cursor: pointer;
        }
        .signup-text {
          width: 100%;
          .label {
            text-transform: uppercase;
            font-size: 16px;
            color: #929292;
          }
          input {
            margin-top: 8px;
            font-size: 16px;
            width: 100%;
            border: none;
            outline: none;
            border-bottom: 1px solid rgba(119, 119, 119, 0.5);

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #929292;
              font-size: 15px;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              font-size: 15px;
              color: #929292;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              font-size: 15px;
              color: #929292;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      justify-content: space-around;
      .left {
        display: none;
      }
      .right {
        max-width: 500px;
        width: 100%;
        padding: 0px 10px;
        margin-right: 0px;
        .subscribe {
          margin-top: 37px;
          a {
            width: calc(50% - 20px);
          }
          .quiz {
            margin-right: 20px;
          }
          justify-content: space-between;
        }
        .links {
          margin-top: 30px;
          display: flex;
          ::v-deep .column {
            margin-right: 0px;
            flex: 1 0 auto;
          }
          margin-bottom: 20px;
        }
        .social-section {
          margin-bottom: 20px;
        }
        div.mailing-list {
          margin-top: 29px;
        }
      }
    }
  }
  .sub_footer {
    height: 73px;
    background-color: #f2f2f2;
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    color: #bababa;
    position: relative;

    div.brand {
      text-align: center;
      padding-top: 23px;
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      height: calc(100% - 23px);
    }
    div.spacer {
      margin-left: 96px;
    }
    .privacy {
      position: absolute;
      right: 72px;
      top: 23px;
      a {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    @media (max-width: 767px) {
      div.spacer {
        margin-left: 0px;
      }
      div.brand {
        width: 50%;
        text-align: left;
        padding-left: 15px;
      }
      .privacy {
        right: 15px;
      }
    }
  }
  &.compact {
    .main_footer {
      display: none;
    }
  }
}
</style>
