var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer",
      class: { compact: _vm.compact },
      attrs: { id: "footer-app" }
    },
    [
      _c("div", { staticClass: "main_footer" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("img", {
              staticClass: "logo",
              attrs: {
                src: "/skin/frontend/default/sns_korion/images/logo.gif"
              }
            }),
            _vm._v(" "),
            _c("FooterLinks", { staticClass: "links" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "social-section" },
              [
                _c("FooterSocial", { attrs: { lazy: _vm.lazy } }),
                _vm._v(" "),
                _c("FooterSignup", {
                  staticClass: "signup",
                  attrs: { lazy: _vm.lazy }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("FooterLinks", { staticClass: "links" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "social-section" },
              [
                _c("FooterSocial", { attrs: { lazy: _vm.lazy } }),
                _vm._v(" "),
                _c("FooterSignup", {
                  staticClass: "signup",
                  attrs: { lazy: _vm.lazy }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subscribe" }, [
      _c("a", { staticClass: "quiz", attrs: { href: "/quiz" } }, [
        _vm._v("Subscribe")
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "gift", attrs: { href: "/gift" } }, [
        _vm._v("Gift")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sub_footer" }, [
      _c("div", { staticClass: "spacer" }),
      _vm._v(" "),
      _c("div", { staticClass: "brand" }, [_vm._v("© 2021 MistoBox")]),
      _vm._v(" "),
      _c("div", { staticClass: "privacy" }, [
        _c("a", { attrs: { href: "/privacy-policy", target: "_new" } }, [
          _vm._v("Privacy Policy")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }